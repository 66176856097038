import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  CircularProgress,
  Typography,
  Button,
  ButtonGroup,
  Grid,
  ListItem,
  ListItemText,
  styled,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import DateTimeFormatter from "components/authentication_v2/DateTimeFormatter";
import Map from "./Map";

const ButtonStyled = styled(Button)(({ theme }) => ({
  backgroundColor: "rgba(255, 119, 1, 1)",
  color: "white",
  fontSize: "11px",
  fontWeight: "500",
  minHeight: "1.7rem",
  padding: "4px 8px", // Adjust padding here for smaller button appearance
  "&:hover": {
    backgroundColor: "white",
    color: theme.palette.primary.main,
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(255, 119, 1, 1)",
    },
  },
  components: {
    MuiCircularProgress: {
      styleOverrides: {
        circle: {
          color: "white",
        },
      },
    },
  },
});

function AppointmentInfo({
  appointment,
  handleDownloadPdf,
  handleDownloadCsv,
  handleDownloadXls,
  loadingPdf,
  loadingCsv,
  loadingXls,
}) {
  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        sx={{
          // boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          backgroundColor: "white",
          borderRadius: 2,
        }}
      >
        {appointment && (
          <Grid container spacing={2} sx={{ padding: 0 }}>
            <Grid item xs={12} sm={4} lg={3}>
              <Map
                appointmentsOrOICLeads={[appointment]}
                fetchCampaignAppointmentsOrOics={null}
                openAppointmentModal={null}
                campaignType={appointment.campaign_type}
                campaignId={appointment.campaign_id}
              />
            </Grid>
            <Grid item xs={12} sm={3} lg={2} spacing={2} flexDirection="row">
              <Grid item xs={12} md={12}>
                <ListItem>
                  <ListItemText
                    sx={{ display: "flex", flexDirection: "column", alignItems: "right", gap: 1 }}
                    primary={
                      <Typography component="div" sx={{ fontSize: "0.875rem", fontWeight: "bold" }}>
                        Campaign Name
                      </Typography>
                    }
                    secondary={
                      <Typography component="div" sx={{ fontSize: "0.875rem" }}>
                        {appointment.campaign_name.toString()}
                      </Typography>
                    }
                  />
                </ListItem>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3} lg={2} spacing={2} flexDirection="row">
              <Grid item xs={12} md={12}>
                <ListItem>
                  <ListItemText
                    sx={{ display: "flex", flexDirection: "column", alignItems: "right", gap: 1 }}
                    primary={
                      <Typography component="div" sx={{ fontSize: "0.875rem", fontWeight: "bold" }}>
                        Processed at
                        <br />
                      </Typography>
                    }
                    secondary={
                      <Typography component="div" sx={{ fontSize: "0.875rem" }}>
                        <DateTimeFormatter dateTime={appointment.campaign_lead.processed_at} />
                      </Typography>
                    }
                  />
                </ListItem>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3} lg={2}>
              <Box
                sx={{
                  // display: "flex",
                  flexDirection: "row", // Stack children vertically
                  alignItems: "flex-end", // Align items to the right
                  justifyContent: "flex-end", // Align items to the top
                  width: "100%", // Ensure the Box fills the Grid item
                  height: "100%", // Ensures Box takes full height of its container
                  textAlign: "right",
                }}
              >
                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                  {[
                    { handler: handleDownloadPdf, loading: loadingPdf },
                    { handler: handleDownloadCsv, loading: loadingCsv },
                    { handler: handleDownloadXls, loading: loadingXls },
                  ].map(({ handler, loading }, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <ButtonStyled key={index} onClick={handler}>
                      {loading ? (
                        <CircularProgress size={25} sx={{ color: "primary.main" }} />
                      ) : (
                        <p>{["Pdf", "Csv", "Xls"][index]}</p>
                      )}
                    </ButtonStyled>
                  ))}
                </ButtonGroup>
              </Box>
            </Grid>
          </Grid>
        )}
      </Grid>
    </ThemeProvider>
  );
}

AppointmentInfo.propTypes = {
  appointment: PropTypes.object.isRequired,
  handleDownloadPdf: PropTypes.func.isRequired,
  handleDownloadXls: PropTypes.func.isRequired,
  handleDownloadCsv: PropTypes.func.isRequired,
  loadingPdf: PropTypes.bool.isRequired,
  loadingCsv: PropTypes.bool.isRequired,
  loadingXls: PropTypes.bool.isRequired,
};

export default AppointmentInfo;
