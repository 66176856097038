/* eslint-disable react/require-default-props */
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import RoiChartItem from "components/authentication_v2/RoiChartItem";

function ROICardChart({ title, description, items, activeItem, setActiveItem }) {
  const handleItemClick = (label) => {
    setActiveItem(label);
  };

  const renderItems = items.map(({ label, desc, progress }) => (
    <Grid item xs={12} md={4} mb={2} lg={3.8} ml={1.5} key={label}>
      {activeItem !== "" ? (
        <RoiChartItem
          label={label}
          isActive={activeItem === label}
          desc={desc}
          progress={{
            content: progress.content,
            percentage: progress.percentage,
            currency: progress.currency,
          }}
          onClick={() => handleItemClick(label)}
        />
      ) : (
        <RoiChartItem
          label={label}
          desc={desc}
          progress={{
            content: progress.content,
            percentage: progress.percentage,
            currency: progress.currency,
          }}
        />
      )}
    </Grid>
  ));

  return (
    <Grid item>
      <SuiBox>
        <div style={{ cursor: "pointer" }}>
          <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {title}
          </SuiTypography>
          <SuiTypography component="div" color="text" fontWeight="regular">
            <Grid item md={12} pb={5} pl={2}>
              ADVICE: {description}
            </Grid>
          </SuiTypography>
          <Grid container spacing={2}>
            {renderItems}
          </Grid>
        </div>
      </SuiBox>
    </Grid>
  );
}

ROICardChart.defaultProps = {
  description: "",
  items: [],
  activeItem: "",
};

ROICardChart.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  items: PropTypes.arrayOf(PropTypes.object),
  activeItem: PropTypes.string,
  setActiveItem: PropTypes.func.isRequired,
};

export default ROICardChart;
