import React, { useEffect, useState, useContext } from "react";
import { Box, CircularProgress, Grid, Stack, ThemeProvider, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { CustomerUserContext } from "App";
import PropTypes from "prop-types";
import { useSoftUIController } from "context";
import { createTheme } from "@mui/material/styles";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar/Navbar";
import CustomerUserRoles from "common/constants/customer-user-roles";
import Swal from "sweetalert2";
import axios from "axios";
import Footer from "examples/Footer";
import CompanyProfile from "layouts/pages/company-profile/CompanyProfile";
import UserProfile from "./components/UserProfile";
import CompanyInfo from "./components/CompanyInfo";
import EmployeesInfo from "./components/employeesInfo/EmployeesInfo";

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

TabPanel.defaultProps = {
  children: null,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const useStyles = makeStyles(() => ({
  tabs: { borderBottom: "1px solid #5F5F5F33" },
}));

function Profile() {
  const [controller] = useSoftUIController();

  const classes = useStyles();

  const [value, setValue] = useState(0);
  const [style, setStyle] = useState({});

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setStyle({});
  };
  const theme = createTheme({
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            backgroundColor: "transparent",
            color: "#4A4A4A",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontWeight: "400",
            textTransform: "capitalize",
            paddingBottom: "30px",
            "&.Mui-selected": {
              backgroundColor: "transparent",
              borderBottom: "1px solid #FF4D00D9",
              borderRadius: "0",
              color: "#FF4D00D9",
              fontSize: "16px",
              fontWeight: "600",
              fontFamily: "Poppins",
              textTransform: "capitalize",
            },
          },
        },
      },
      MuiCircularProgress: {
        styleOverrides: {
          circle: {
            color: "lightgrey",
          },
        },
      },
    },
  });

  const [data, setData] = useState(null);
  const [loadingProfile, setLoadingProfile] = useState(false);

  const [customerUser] = useContext(CustomerUserContext);

  const refreshData = () => {
    setLoadingProfile(true);
    axios
      .get(`/api/customers/${customerUser.customer.id}/profile`)
      .then((r) => {
        // Swal.fire("Success!", r.message, "success");
        setData(r.data);
      })
      .catch((err) => {
        Swal.fire("Error!", err.message, "error");
      })
      .finally(() => {
        setLoadingProfile(false);
      });
  };
  useEffect(() => {
    refreshData();
    return () => {};
  }, []);

  const loader2 = (
    <Stack
      alignItems="center"
      className="loader"
      display="flex"
      justifyContent="center"
      height="100vh"
      width="100%"
    >
      <CircularProgress /> Loading..
    </Stack>
  );

  return data ? (
    <>
      <DashboardLayout>
        <DashboardNavbar componentName="Account Profile" />
      </DashboardLayout>
      <ThemeProvider theme={theme}>
        {loadingProfile ? (
          loader2
        ) : (
          <Grid
            container
            sx={{ backgroundColor: "#FBFAFB", overflow: "hidden" }}
            ml={controller.miniSidenav ? "100px" : "300px"}
            md={controller.miniSidenav ? 10.8 : 9}
          >
            <Grid container>
              <Grid item md={12} className={classes.tabs}>
                <Tabs
                  classes={{ flexContainer: classes.flexContainer }}
                  TabIndicatorProps={{ style: { display: "none" } }}
                  value={value}
                  style={{ marginBottom: "0", padding: "0" }}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    theme={theme}
                    label="User Profile"
                    {...a11yProps(0)}
                    style={value === 0 ? style : {}}
                  />
                  <Tab
                    theme={theme}
                    label="Company Profile"
                    {...a11yProps(1)}
                    style={value === 1 ? style : {}}
                  />
                  <Tab
                    theme={theme}
                    label="Employees Info"
                    {...a11yProps(2)}
                    style={value === 2 ? style : {}}
                    sx={
                      data.company && customerUser.customer_role === CustomerUserRoles.ADMIN
                        ? { display: "flex" }
                        : { display: "none" }
                    }
                  />
                </Tabs>
              </Grid>
            </Grid>
            <Grid container ml="-16px">
              <Grid item md={12}>
                <TabPanel value={value} index={0}>
                  <UserProfile
                    data={data}
                    refreshData={refreshData}
                    customerId={customerUser.customer.id}
                  />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <CompanyProfile />
                  {data.company && customerUser.customer_role === CustomerUserRoles.ADMIN && (
                    <CompanyInfo data={data} customerId={customerUser.customer.id} />
                  )}
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <EmployeesInfo
                    data={data}
                    refreshUserInfo={refreshData}
                    customerId={customerUser.customer.id}
                  />
                </TabPanel>
              </Grid>
            </Grid>
            <Footer />
          </Grid>
        )}
      </ThemeProvider>
    </>
  ) : (
    ""
  );
}

export default Profile;
