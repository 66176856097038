/* eslint-disable camelcase */
import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Button,
  TextField,
  Card as MUICard,
  Box,
  Stack,
  Icon,
  Grid,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@mui/material/LinearProgress";
import axios from "axios";
import Swal from "sweetalert2";
import Star from "./iconComponents/Star";
import DateTimeFormatter from "./DateTimeFormatter";

const useStyles = makeStyles(() => ({
  kanbanColumn: {
    backgroundColor: "white",
    margin: "1%",
    borderRadius: "5px",
    borderRight: "1px solid #5F5F5F26",
    paddingRight: "3%",
    paddingLeft: "0.5%",
    minWidth: "260px",
    minHeight: "260px",
  },
  card: {
    width: "230px",
    borderRadius: "5px",
    marginTop: "6%",
    padding: "3% 5%",
    fontSize: "14px",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    position: "relative",
  },
  status: { fontSize: "14px", fontWeight: "700", fontFamily: "Poppins" },
  filteredData: { fontSize: "14px", fontWeight: "600", fontFamily: "Poppins" },
  itemStatus: {
    borderRadius: "5px",
    padding: "2% 7%",
    fontSize: "10px",
    cursor: "pointer",
    textAlign: "center",
    fontWeight: "700",
    marginBottom: "3%",
    fontFamily: "Poppins",
  },
  lead: { fontSize: "14px", fontWeight: "500", fontFamily: "Poppins" },
  appointment: {
    fontSize: "10px",
    fontWeight: "500",
    marginBottom: "4%",
    fontFamily: "Poppins",
  },
}));

function getStatusBorderColor(status) {
  switch (status) {
    case "Favorites":
      return "#2F9D68";
    case "Follow Up":
      return "#6ef0b0";
    case "Offers":
      return "#53cc91";
    case "Deals":
      return "#0e9553";
    default:
      return "#92CAFF";
  }
}

function getColor(status) {
  switch (status) {
    case "Favorites":
      return "#71D8A6";
    case "Follow Up":
      return "#6ef0b0";
    case "Offers":
      return "#53cc91";
    case "Deals":
      return "#0e9553";
    default:
      return "#75BDFF";
  }
}

function OicKanbanData({
  data,
  openAppointment,
  handleFavoriteClick,
  isFavourite,
  customerUser,
  fetchCampaignAppointmentsOrOics,
}) {
  const classes = useStyles();
  const [newCardForm, setNewCardForm] = useState(false);
  const [formValue, setFormValue] = useState("");
  const [draggedItem, setDraggedItem] = useState(null);

  const handleDrop = (status) => {
    let review_status = "";
    switch (status) {
      case "Follow Up":
        review_status = "follow_up";
        break;
      case "Offers":
        review_status = "offer";
        break;
      case "Deals":
        review_status = "deal";
        break;
      default:
        review_status = "";
        break;
    }

    const payload = {
      review_status,
    };
    axios
      .post(
        `/api/customers/${customerUser.customer.id}/campaign-leads/${draggedItem.campaign_lead_id}/review-status`,
        payload
      )
      .then(() => {
        fetchCampaignAppointmentsOrOics(0);
        setDraggedItem(null);
      })
      .catch((err) => {
        Swal.fire("Error!", err.message, "error");
      });
  };

  const handleCardClick = (id, className) => {
    openAppointment({ id, class_name: className });
  };
  const [search, setSearch] = useState("");

  const onSearchChange = (e) => {
    setSearch(e.target.value);
  };
  return (
    <Box height="300px" mt={2} overflow="auto">
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        direction={{ xs: "column", md: "row" }}
        padding={1.5}
      >
        <Typography fontSize="20px" fontWeight="600" color="#010101">
          OIC Leads
        </Typography>
        <Stack
          direction="row"
          sx={{
            backgroundColor: "#F7F7F7",
            borderRadius: "5px",
            paddingTop: "0.5%",
            paddingBottom: "0.5%",
            paddingLeft: "1%",
            marginTop: "10px",
          }}
          alignItems="center"
        >
          <Icon sx={{ color: "#FF4D00D9" }}>search</Icon>
          <input
            style={{
              border: "0",
              background: "transparent",
              outline: "none",
            }}
            type="text"
            value={search}
            placeholder="Search OIC Lead"
            onChange={onSearchChange}
          />
        </Stack>
      </Grid>
      <Box my={4} className={classes.container}>
        {["Favorites", "Follow Up", "Offers", "Deals"].map((status) => {
          const filteredData = data.filter((item) => {
            const searchedData = item.lead_identificator
              .toLowerCase()
              .includes(search.toLowerCase());

            if (isFavourite === 1 && item.is_favorite !== 1) {
              return false;
            }

            if (
              status === "Favorites" &&
              (item.customer_review_status === null || item.customer_review_status === "favorite")
            ) {
              return item.class_name === "success" && searchedData;
            }
            if (status === "Follow Up") {
              return item.customer_review_status === "follow_up" && searchedData;
            }
            if (status === "Offers") {
              return item.customer_review_status === "offer" && searchedData;
            }
            if (status === "Deals") {
              return item.customer_review_status === "deal" && searchedData;
            }
            return false;
          });
          const statusColor = getColor(status);

          return (
            <Box
              key={status}
              className={`${classes.kanbanColumn} ${
                draggedItem?.status === status ? classes.validDrop : ""
              }`}
              onDragOver={(e) => e.preventDefault()}
              onDragEnter={(e) => e.target.classList.add(classes.validDrop)}
              onDragLeave={(e) => e.target.classList.remove(classes.validDrop)}
              onDrop={() => handleDrop(status)}
            >
              <Stack direction="row" alignItems="center" gap={0.5}>
                <Typography className={classes.status}>{status}</Typography>
                <Typography
                  sx={{
                    color: statusColor,
                  }}
                  className={classes.filteredData}
                >
                  ({filteredData.length})
                </Typography>
              </Stack>
              {filteredData.map((item) => (
                <MUICard
                  key={item.id}
                  className={classes.card}
                  style={{
                    border: `1px solid ${getStatusBorderColor(status)}`,
                    opacity: draggedItem?.id === item.id ? 0.5 : 1,
                    cursor: "pointer",
                  }}
                  draggable
                  onDragStart={() => setDraggedItem(item)}
                  onDragEnd={() => setDraggedItem(item)}
                  onClick={() => handleCardClick(item.id, item.class_name)}
                >
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Typography className={classes.lead}>
                      {item.lead_identificator.split(" /")[0].replace("Firmenname: ", "")}
                    </Typography>
                    <LinearProgress
                      onClick={() => handleCardClick(item.id, item.class_name)}
                      sx={{
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: getStatusBorderColor(item.status),
                        },
                        width: item.client_rating,
                      }}
                      variant="determinate"
                      value={item.client_rating}
                    />
                  </Stack>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography className={classes.appointment}>
                      <DateTimeFormatter dateTime={item.created_at} />
                    </Typography>
                    <Typography
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        handleFavoriteClick(item);
                      }}
                    >
                      <Star fill={item.is_favorite === 1 ? "#EBCB24" : "transparent"} />
                    </Typography>
                  </Stack>
                </MUICard>
              ))}
            </Box>
          );
        })}
        {/* New card form */}
        {newCardForm && (
          <Box className={classes.kanbanColumn}>
            <Box textAlign="center">
              <Typography variant="h6">{newCardForm}</Typography>
            </Box>
            <Box my={2.5}>
              <TextField
                value={formValue}
                multiline
                rows={2}
                onChange={(e) => setFormValue(e.target.value)}
              />
              <Box display="flex" mt={2}>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  onClick={() => {
                    setFormValue("");
                  }}
                >
                  Add
                </Button>
                <Box ml={1}>
                  <Button
                    variant="contained"
                    color="light"
                    size="small"
                    onClick={() => setNewCardForm(false)}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

OicKanbanData.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      className: PropTypes.string.isRequired,
      info: PropTypes.string.isRequired,
      cards: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          template: PropTypes.node.isRequired,
          className: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  openAppointment: PropTypes.func.isRequired,
  isFavourite: PropTypes.number.isRequired,
  handleFavoriteClick: PropTypes.func.isRequired,
  customerUser: PropTypes.any.isRequired,
  fetchCampaignAppointmentsOrOics: PropTypes.any.isRequired,
};

export default OicKanbanData;
