import React, { useEffect, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Grid, Stack, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";
import AuthButton from "components/Buttons/AuthButton";
import Swal from "sweetalert2";
import axios from "axios";

const useStyles = makeStyles(() => ({
  container: { display: "flex", flexDirection: "column", alignItems: "center" },
  inputs: {
    width: "100%",
  },
  inputs2: { width: "70%" },
  defaultTypo: { fontSize: "14px", fontFamily: "Poppins", fontWeight: "400", color: "#5F5F5F" },
  errorTypo: { fontSize: "14px", fontFamily: "Poppins", color: "red" },
  resetTypo: {
    color: "#00000096",
    fontSize: "12px",
    fontFamily: "Poppins",
    textTransform: "none",
    fontWeight: "600",
  },
  forgetTypo: { color: "#8D8D8D", fontSize: "12px", fontFamily: "Poppins", fontWeight: "600" },
}));
const theme = createTheme({
  components: {
    MuiCircularProgress: {
      styleOverrides: {
        circle: {
          color: "#fff",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontSize: "12px",
          fontFamily: "Poppins",
          fontWeight: "400",
          color: "#5F5F5F",
        },
      },
    },
  },
});

function CompanyInfo({ data, customerId }) {
  const classes = useStyles();

  const [companyInfo, setCompanyInfo] = useState({
    name: null,
    description: null,
    contact_person: null,
    address: null,
    zip_code: null,
    city: null,
    phone_num_1: null,
    phone_num_2: null,
    email: null,
    domain: null,
  });

  useEffect(() => {
    setCompanyInfo({ ...data.company });

    return () => {
      setCompanyInfo({
        name: null,
        description: null,
        contact_person: null,
        address: null,
        zip_code: null,
        city: null,
        phone_num_1: null,
        phone_num_2: null,
        email: null,
        domain: null,
      });
    };
  }, []);

  const handleInput = (e) => {
    e.persist();
    setCompanyInfo({ ...companyInfo, [e.target.name]: e.target.value });
  };

  const submitCompanyinfo = (e) => {
    const enrichData = false;
    e.preventDefault();

    // Swal.fire({
    //   title: `Do you want to enrich data for Customer?`,
    //   icon: "warning",
    //   showCancelButton: true,
    //   confirmButtonColor: "green",
    //   cancelButtonColor: "rgba(211, 47, 47, 1)",
    //   confirmButtonText: "Confirm!",
    // })
    //   .then((result) => {
    //     if (result.isConfirmed) {
    //       enrichData = true;
    //     }
    //   })
    //   .finally(() => {

    //   });

    axios
      .put(`/api/customers/${customerId}/profile/update-company-info`, {
        ...companyInfo,
        enrichData,
      })
      .then((res) => {
        setCompanyInfo({ ...companyInfo, error_list: [] });
        Swal.fire({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
      })
      .catch((error) => {
        setCompanyInfo({ ...companyInfo, error_list: [] });
        const errorData = error.response === undefined ? null : error.response.data;

        if (errorData && errorData.errors !== undefined) {
          setCompanyInfo({ ...companyInfo, error_list: errorData.errors });
        }

        if (errorData && errorData.message !== undefined) {
          Swal.fire({
            title: "Error!",
            text: errorData.message,
            icon: "error",
          });
        }
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        sx={{
          backgroundColor: "#FFFFFF",
          padding: "20px",
        }}
      >
        <Grid item md={12} sm={12} xs={12}>
          <Typography
            textAlign="left"
            fontSize="16px"
            fontWeight="700"
            fontFamily="Poppins"
            color="#4A4A4A"
            letterSpacing="0.5px"
            mb={5}
          >
            Company Information
          </Typography>
        </Grid>
        <Grid item md={3.5} sm={5} xs={12}>
          <Stack mb={4}>
            <Typography className={classes.defaultTypo} mb={1.5}>
              Name
            </Typography>
            <TextField
              className={classes.inputs2}
              name="name"
              type="text"
              variant="standard"
              defaultValue={data.company.name}
              onChange={handleInput}
            />
            {companyInfo.error_list && companyInfo.error_list.name ? (
              <Typography className={classes.errorTypo}>{companyInfo.error_list.name}</Typography>
            ) : (
              ""
            )}
          </Stack>
          <Stack mb={4}>
            <Typography className={classes.defaultTypo} mb={1.5}>
              Description
            </Typography>
            <TextField
              className={classes.inputs2}
              name="description"
              type="text"
              variant="standard"
              placeholder="Company Description"
              defaultValue={data.company.description}
              onChange={handleInput}
            />
            {companyInfo.error_list && companyInfo.error_list.description ? (
              <Typography className={classes.errorTypo}>
                {companyInfo.error_list.description}
              </Typography>
            ) : (
              ""
            )}
          </Stack>
          <Stack mb={4}>
            <Typography className={classes.defaultTypo} mb={1.5}>
              Contact Person
            </Typography>
            <TextField
              className={classes.inputs2}
              name="contact_person"
              type="text"
              variant="standard"
              placeholder="Contact Person"
              defaultValue={data.company.contact_person}
              onChange={handleInput}
            />
            {companyInfo.error_list && companyInfo.error_list.contact_person ? (
              <Typography className={classes.errorTypo}>
                {companyInfo.error_list.contact_person}
              </Typography>
            ) : (
              ""
            )}
          </Stack>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            display: { xs: "none", sm: "none", md: "block" },
          }}
        />
        <Grid
          item
          sx={{
            marginLeft: { xs: "0", sm: "10%", md: "6%" },
          }}
          md={4}
          sm={5}
          xs={12}
        >
          <Stack mb={4}>
            <Typography className={classes.defaultTypo} mb={1.5}>
              City
            </Typography>
            <TextField
              className={classes.inputs2}
              name="city"
              type="text"
              variant="standard"
              placeholder="City"
              defaultValue={data.company.city}
              onChange={handleInput}
            />
            {companyInfo.error_list && companyInfo.error_list.city ? (
              <Typography className={classes.errorTypo}>{companyInfo.error_list.city}</Typography>
            ) : (
              ""
            )}
          </Stack>
          <Stack mb={4}>
            <Typography className={classes.defaultTypo} mb={1.5}>
              Address
            </Typography>
            <TextField
              className={classes.inputs2}
              name="address"
              type="text"
              variant="standard"
              placeholder="Address"
              defaultValue={data.company.address}
              onChange={handleInput}
            />
            {companyInfo.error_list && companyInfo.error_list.address ? (
              <Typography className={classes.errorTypo}>
                {companyInfo.error_list.address}
              </Typography>
            ) : (
              ""
            )}
          </Stack>
          <Stack mb={4}>
            <Typography className={classes.defaultTypo} mb={1.5}>
              Zip Code
            </Typography>
            <TextField
              className={classes.inputs2}
              name="zip_code"
              type="text"
              variant="standard"
              placeholder="00000"
              defaultValue={data.company.zip_code}
              onChange={handleInput}
            />
            {companyInfo.error_list && companyInfo.error_list.zip_code ? (
              <Typography className={classes.errorTypo}>
                {companyInfo.error_list.zip_code}
              </Typography>
            ) : (
              ""
            )}
          </Stack>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            display: { xs: "none", sm: "none", md: "block" },
          }}
        />
        <Grid
          item
          sx={{
            marginLeft: { xs: "0", sm: "10%", md: "6%" },
          }}
          md={3}
          sm={5}
          xs={12}
        >
          <Stack mb={4}>
            <Typography className={classes.defaultTypo} mb={1.5}>
              Phone Number 1
            </Typography>
            <TextField
              sx={{ width: { xs: "70%", sm: "90%", md: "90%" } }}
              name="phone_num_1"
              type="text"
              variant="standard"
              placeholder="+0000000"
              defaultValue={data.company.phone_num_1}
              onChange={handleInput}
            />
            {companyInfo.error_list && companyInfo.error_list.phone_num_1 ? (
              <Typography className={classes.errorTypo}>
                {companyInfo.error_list.phone_num_1}
              </Typography>
            ) : (
              ""
            )}
          </Stack>
          <Stack mb={4}>
            <Typography className={classes.defaultTypo} mb={1.5}>
              Phone Number 2
            </Typography>
            <TextField
              sx={{ width: { xs: "70%", sm: "90%", md: "90%" } }}
              name="phone_num_2"
              type="text"
              variant="standard"
              placeholder="+0000000"
              defaultValue={data.company.phone_num_2}
              onChange={handleInput}
            />
            {companyInfo.error_list && companyInfo.error_list.phone_num_2 ? (
              <Typography className={classes.errorTypo}>
                {companyInfo.error_list.phone_num_2}
              </Typography>
            ) : (
              ""
            )}
          </Stack>
          <Stack mb={4}>
            <Typography className={classes.defaultTypo} mb={1.5}>
              Email
            </Typography>
            <TextField
              sx={{ width: { xs: "70%", sm: "90%", md: "90%" } }}
              name="email"
              type="text"
              variant="standard"
              placeholder="@gmail.com"
              defaultValue={data.company.email}
              onChange={handleInput}
            />
            {companyInfo.error_list && companyInfo.error_list.email ? (
              <Typography className={classes.errorTypo}>{companyInfo.error_list.email}</Typography>
            ) : (
              ""
            )}
          </Stack>
          <Stack mb={4}>
            <Typography className={classes.defaultTypo} mb={1.5}>
              Domain
            </Typography>
            <TextField
              sx={{ width: { xs: "70%", sm: "90%", md: "90%" } }}
              name="domain"
              type="text"
              variant="standard"
              placeholder="example.com"
              defaultValue={data.company.domain}
              onChange={handleInput}
              disabled
            />
            {companyInfo.error_list && companyInfo.error_list.domain ? (
              <Typography className={classes.errorTypo}>{companyInfo.error_list.domain}</Typography>
            ) : (
              ""
            )}
          </Stack>
        </Grid>
        <Grid container mt={5}>
          <Grid item md={8.4} sm={3} />
          <Grid item md={3.3} xs={12}>
            <AuthButton onClick={submitCompanyinfo} fontSize="14px" backgroundColor="#FF4D00D9">
              UPDATE COMPANY INFORMATION
            </AuthButton>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

CompanyInfo.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  customerId: PropTypes.number.isRequired,
};

export default CompanyInfo;
