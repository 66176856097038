/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Soft UI Dashboard PRO React layouts

// import OrderList from "layouts/ecommerce/orders/order-list";

// Soft UI Dashboard PRO React icons

// import Profile from "layouts/pages/account/profile";
import Chat from "layouts/pages/chat_v2/Chat";
// import Appointments from "layouts/dashboards/appointments";
import Appointments from "layouts/dashboards/appointments_v2/Appointments";
// import VRInfo from "layouts/dashboards/virtual-reality/vr-info";
// import OIC from "layouts/dashboards/oic";
import Enrichment from "layouts/enrichment";
import OicLeads from "layouts/dashboards/oic_v2/OicLeads";
import Profile from "layouts/pages/account/profile_v2/Profile";
import FileManager from "layouts/pages/file-manager/FileManager";
import EnrichmentOic from "layouts/enrichment-oic";
import Dashboard from "layouts/pages/dashboard/Dashboard";
import EmployeeInfo from "layouts/employee-info";
import ROIAnalytics from "layouts/analytics/ROIAnalytics";
import OicEmployeeInfo from "layouts/oic-employee-info";

const privateRoutes = [
  // {
  //   name: "VR Info",
  //   key: "vr-info",
  //   route: "/",
  //   component: VRInfo,
  // },
  // {
  //   name: "Dashboard",
  //   key: "dashboards",
  //   route: "/",
  //   component: Appointments,
  // },
  {
    name: "Dashboard",
    key: "dashboards",
    route: "/",
    component: Dashboard,
  },
  {
    name: "Appointments",
    key: "appointments",
    route: "/appointments",
    component: Appointments,
  },
  {
    name: "OIC Leads",
    key: "oic",
    route: "/oic",
    component: OicLeads,
  },
  { type: "title", title: "Pages", key: "title-pages" },
  {
    name: "Account Profile",
    key: "profile",
    route: "/pages/account/profile",
    component: Profile,
  },
  // {
  //   name: "Documents",
  //   key: "documents",
  //   route: "/documents",
  //   component: OrderList,
  // },

  {
    name: "Chat",
    key: "chat",
    route: "/pages/chat",
    component: Chat,
  },
  {
    name: "KPIs",
    key: "kpis",
    route: "/kpis",
    component: ROIAnalytics,

    // collapse: [
    //   // {
    //   //   name: "Appointments Analytics",
    //   //   key: "appointment-analytics",
    //   //   route: "/appointment-analytics",
    //   //   component: AppointmentAnalytics,
    //   // },
    //   {
    //     name: "OIC Analytics",
    //     key: "oic-analytics",
    //     route: "/oic-analytics",
    //     component: OicLeadsAnalytics,
    //   },
    //   {
    //     name: "ROI",
    //     key: "roi",
    //     route: "/roi",
    //     component: ROIAnalytics,
    //   },
    // ],
  },
  {
    name: "Enrichment",
    key: "enrichment",
    route: "/enrichment",
    component: Enrichment,
  },
  {
    name: "Enrichment OIC",
    key: "enrichment-oic",
    route: "/enrichment-oic",
    component: EnrichmentOic,
  },
  {
    name: "File Manager",
    key: "file-manager",
    route: "/file-manager",
    component: FileManager,
  },
  {
    name: "Employee Profile",
    key: "employee-profile",
    route: "/employee-profile",
    component: EmployeeInfo,
  },
  {
    name: "OIC Employee Profile",
    key: "oic-employee-profile",
    route: "/oic-employee-profile",
    component: OicEmployeeInfo,
  },
];

export default privateRoutes;
