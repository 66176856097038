/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";

// Soft UI Dashboard PRO React example components
// import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
// import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsBarChart from "examples/Charts/BarCharts_v2/ReportChart";

// Data
import SuiVectorMapRoot from "components/SuiVectorMap/SuiVectorMapRoot";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { CircularProgress, Stack, ThemeProvider, createTheme } from "@mui/material";
// import SuiInput from "components/SuiInput";
import CampaignTypes from "common/constants/campaign-types";
import { dateToYMD } from "common/helpers";
import { CustomerUserContext } from "App";

import OICModal from "../appointments/components/OICModal";

const EmbeddedOicLeads = () => {
  const history = useHistory();
  const [customerUser] = useContext(CustomerUserContext);

  const [today, setToday] = useState(new Date());

  const [oicLeadsVectorMap, setOICLeadsVectorMap] = useState({});
  const [campaignId] = useState(0);

  const [infoAppts, setInfoAppts] = useState([]);

  // const [loadingCampaigns, setLoadingCampaigns] = useState(true);
  const [loadingCampaignItems, setLoadingCampaignItems] = useState(true);

  const [openOICModal, setOpenOICModal] = useState(false);
  const [selectedOicLead, setSelectedOicLead] = useState(null);
  const handleOpenOICModal = (pOICLeadId) => {
    history.push({
      pathname: "/enrichment-oic",
      state: {
        appointmentIdParam: pOICLeadId,
        customerUser: { customerUser },
        campaignId: { campaignId },
        appointmentId: { selectedOicLead },
      },
    });
    // setSelectedOicLead(pOICLeadId);
    // setOpenOICModal(true);
  };
  const handleCloseOICModal = () => {
    setSelectedOicLead(null);
    setOpenOICModal(false);
  };
  const themeGrid = createTheme({
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: "6px !important",
            boxShadow: "none",
            background: "none !important",
            minHeight: "390px",
          },
        },
      },
      MuiBox: {
        styleOverrides: {
          root: {
            borderRadius: "6px !important",
            boxShadow: "none",
            backgroundColor: "none !important",
            minHeight: "390px",
          },
        },
      },
    },
  });
  const [resset, setResset] = useState({
    cols: null,
    res: null,
  });

  const fetchRes = (cols, res) => {
    setOICLeadsVectorMap([]);
    const rSet = res.data.data;
    setTimeout(() => {
      setOICLeadsVectorMap(rSet);
    }, 400);

    setLoadingCampaignItems(false);
  };

  useEffect(() => {
    if (resset.cols && resset.res) {
      fetchRes(resset.cols, resset.res);
    }
    return () => {};
  }, []);

  const fetchCampaignAppointmentsOrOics = (pCampaignId = 0) => {
    setLoadingCampaignItems(true);
    axios
      .get(`/api/customers/${customerUser.customer.id}/campaigns/${pCampaignId}/oic/fields`)
      .then((r) => {
        const cols = r.data.data;

        axios
          .get(`/api/customers/${customerUser.customer.id}/campaigns/${pCampaignId}/oic`)
          .then((res) => {
            setResset({
              cols,
              res,
            });
            fetchRes(cols, res);
          })
          .catch(() => {
            fetchRes(cols, null);
            setOICLeadsVectorMap([]);
            setLoadingCampaignItems(false);
          });
      })
      .catch(() => {});
  };

  const fetchCampaigns = () => {
    // setLoadingCampaigns(true);
    setLoadingCampaignItems(true);
    axios
      .get(`/api/customers/${customerUser.customer.id}/campaigns/oic`)
      .then((res) => {
        setInfoAppts([
          // {
          //   icon: { color: "info", component: "touch_app" },
          //   label: "Imported Leads",
          //   progress: { content: res.data.info.num_of_order_request_leads, percentage: 60 },
          // },
          // {
          //   icon: { color: "info", component: "touch_app" },
          //   label: "New Appointments",
          //   progress: { content: res.data.info.new_appointments, percentage: 90 },
          // },
          {
            icon: { color: "warning", component: "payment" },
            label: "new Messages",
            progress: { content: res.data.info.new_messages, percentage: 30 },
          },
          {
            icon: { color: "error", component: "extension" },
            label: "Active Campaigns",
            progress: { content: res.data.info.active_campaigns, percentage: 50 },
          },
          {
            icon: { color: "primary", component: "library_books" },
            label: "All Leads",
            progress: { content: res.data.info.all_leads, percentage: 60 },
          },
          {
            icon: { color: "info", component: "touch_app" },
            label: "Processed",
            progress: { content: res.data.info.total_num_of_booked_appointments, percentage: 60 },
          },
        ]);
        // setLoadingCampaigns(false);
      })
      .catch(() => {
        // setLoadingCampaigns(false);
      });
  };

  const queryParams = new URLSearchParams(window.location.search);

  const oicLeadIdParam = queryParams.get("oicLeadId");

  useEffect(() => {
    if (oicLeadIdParam !== undefined && oicLeadIdParam) {
      handleOpenOICModal(oicLeadIdParam);
    }

    return () => {};
  }, [oicLeadIdParam]);

  useEffect(() => {
    setToday(dateToYMD(new Date()));

    return () => {};
  }, [today]);

  useEffect(() => {
    fetchCampaigns();
    return () => {};
  }, [null, customerUser]);

  useEffect(() => {
    fetchCampaignAppointmentsOrOics(campaignId);
    return () => {};
  }, [campaignId, customerUser]);

  const loader2 = (
    <Stack
      alignItems="center"
      className="loader"
      display="flex"
      justifyContent="center"
      height="40vh"
      width="100%"
    >
      <CircularProgress color="warning" /> Loading..
    </Stack>
  );

  return (
    <>
      {openOICModal && selectedOicLead ? (
        <OICModal
          campaignId={campaignId}
          oicLeadId={selectedOicLead}
          open={openOICModal}
          handleClose={handleCloseOICModal}
          customerUser={customerUser}
        />
      ) : (
        ""
      )}
      <SuiBox>
        <Grid container spacing={3} sx={{ zIndex: 100 }}>
          <Grid item xs={12} lg={12} mb={3}>
            <Grid container>
              <Grid item xs={12} md={12}>
                {loadingCampaignItems ? (
                  loader2
                ) : (
                  <ReportsBarChart title="" description="" chart={{}} items={infoAppts} />
                )}
              </Grid>
            </Grid>
          </Grid>

          <ThemeProvider theme={themeGrid}>
            <Grid
              item
              md={12}
              xs={12}
              lg={12}
              xl={12}
              sx={{ width: "800%", height: "100%", padding: "20px 2px 4px 2px" }}
            >
              <SuiVectorMapRoot
                appointmentsOrOICLeads={oicLeadsVectorMap ?? []}
                fetchCampaignAppointmentsOrOics={fetchCampaignAppointmentsOrOics}
                openAppointmetModal={handleOpenOICModal}
                campaignType={CampaignTypes.OIC}
                campaignId={campaignId}
              />
            </Grid>
          </ThemeProvider>
        </Grid>
      </SuiBox>
    </>
  );
};

export default EmbeddedOicLeads;
