/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Button, Card, Stack, Typography } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Select from "components/Select";
import SuiDatePicker from "components/SuiDatePicker";
import axios from "axios";
import Swal from "sweetalert2";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ChevronDown from "components/Enrichment/components/iconComponents/ChevronDown";
import ChevronUp from "components/Enrichment/components/iconComponents/ChevronUp";
import SuiTypography from "components/SuiTypography";
import KanbanInfo from "./KanbanInfo";
import DynamicCals from "./DynamicCalc";
import SimpleTabInfo from "./SimpleTabInfo";

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
const today = new Date();

function DynamicTabInfo({ customerUser }) {
  const [refreshData, setRefreshData] = useState(false);
  const [campaignData, setCampaignData] = useState([]);
  const [campaignValue, setCampaignValue] = useState([]);
  const [isFavourite, setIsFavourite] = useState(0);
  const [buttonClickFavourite, setButtonClickFavourite] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [style, setStyle] = useState({
    border: "0 !important",
  });
  const [isVisible, setIsVisible] = useState(true);
  const [isRoiVisible, setRoiIsVisible] = useState(true);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const roiVisibillity = () => {
    setRoiIsVisible(!isRoiVisible);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    setStyle({
      border: "0 !important",
    });
  };

  useEffect(() => {
    const storedTabIndex = localStorage.getItem("activeKpiTabIndex");
    if (storedTabIndex !== null) {
      setTabValue(parseInt(storedTabIndex, 10));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("activeKpiTabIndex", tabValue.toString());
  }, [tabValue]);

  const handleFavourite = () => {
    setIsFavourite((prev) => (prev === 0 ? 1 : 0));
    setButtonClickFavourite(!buttonClickFavourite);
  };

  const useStyles = makeStyles(() => ({
    typographyLabelStyle: {
      fontSize: "15px",
    },
    suiBoxStyle: {
      fontSize: "13px",
    },
    main: {
      display: "flex",
      justifyContent: "space-between",
    },
    filters: {
      display: "flex",
      flexDirection: "row",
      gap: "20px",
      "@media (max-width: 580px)": {
        flexDirection: "column",
        alignItems: "center",
      },
    },
    "@media (max-width: 900px)": {
      main: {
        display: "block",
        flexDirection: "column",
      },
      filters: {
        display: "block",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: 0,
      },
      filterTab: {
        display: "block",
        flexDirection: "column",
        alignItems: "center",
      },
    },
    favButton: {
      backgroundColor: buttonClickFavourite ? "#FF4D00D9" : "#E7E7E7",
      color: buttonClickFavourite ? "white" : "#FF4D00D9",
      padding: "5px 35px",
      border: "0",
      borderRadius: "6px",
      cursor: "pointer",
      marginRight: "1%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "@media (max-width: 800px)": {
        marginBottom: "10px",
      },
    },
    datePicker: {
      width: "250px",
      marginBottom: "20px",
      "@media (max-width: 380px)": {
        width: "180px",
      },
    },
    companyInfoWrapper: {
      flex: "left",
      width: "100%",
      padding: "20px",
      overflow: "auto",
    },
    employeeInfoWrapper: {
      flex: "right",
      width: "100%",
      border: "1px solid white",
      padding: "20px",
      backgroundColor: "#f0f0f0",
      overflow: "auto",
    },
    row: {
      display: "flex",
      flexDirection: "column",
      height: "auto",
    },
    "@media (min-width: 1441px)": {
      row: {
        flexDirection: "row",
        height: "95vh",
      },
      companyInfoWrapper: {
        width: "65%",
      },
      employeeInfoWrapper: {
        width: "35%",
      },
    },
    "@media (max-width: 1440px)": {
      row: {
        flexDirection: "row",
        height: "95vh",
      },
      companyInfoWrapper: {
        width: "60%",
      },
      employeeInfoWrapper: {
        width: "40%",
      },
    },
    "@media (max-width: 768px)": {
      row: {
        flexDirection: "column",
        height: "auto",
      },
      companyInfoWrapper: {
        width: "100%",
      },
      employeeInfoWrapper: {
        width: "100%",
      },
    },
    info: {
      display: "flex",
      marginBottom: "5px",
    },
    input: {
      maxWidth: "100px",
    },
  }));
  const classes = useStyles();

  const theme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            display: "none",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            backgroundColor: "#EDEDED",
            color: "#5F5F5F",
            fontFamily: "Poppins",
            padding: "8px 16px 8px 18px",
            borderRadius: "6px",
            textTransform: "none",
            marginRight: "-2%",
            fontSize: "13px",
            fontWeight: "600",
            minHeight: "5px",
            "&.Mui-selected": {
              backgroundColor: "#FF4D00D9",
              color: "#fff",
              fontFamily: "Poppins",
              borderRadius: "6px",
              padding: "8px 16px 8px 18px",
              marginRight: "-2%",
              zIndex: "1",
              fontSize: "13px",
              fontWeight: "600",
            },
          },
        },
      },
    },
  });

  const handleValueChanged = () => {
    setRefreshData(!refreshData);
  };

  const campaignDetail = async () => {
    try {
      const url =
        tabValue === 0
          ? `/api/customers/${customerUser.customer.id}/campaigns/appointments`
          : `/api/customers/${customerUser.customer.id}/campaigns/oic`;
      const response = await axios.get(url);

      if (response && response.data) {
        const campaigns =
          tabValue === 0
            ? response.data.appointmentCampaigns.data
            : response.data.oicLeadCampaigns.data;

        setCampaignData(campaigns);
        setCampaignValue(campaigns.map((campaign) => campaign.id).concat([0]));
      }
    } catch (error) {
      Swal.fire("Error!", error.message, "error");
    }
  };

  useEffect(() => {
    campaignDetail();
  }, []);

  useEffect(() => {
    campaignDetail();
  }, [tabValue]);

  const displayValue = campaignData
    .filter((campaign) => campaignValue.includes(campaign.id))
    .map((campaign) => ({
      value: campaign.id,
      label: campaign.name,
    }));

  const options = [
    ...campaignData.map((campaign) => ({
      value: campaign.id,
      label: campaign.name,
    })),
  ];

  const handleCampaignValueChange = (value) => {
    if (value.some((item) => item.label === "All")) {
      setCampaignValue(campaignData.map((item) => item.id).concat([0]));
    } else {
      setCampaignValue(value.map((item) => item.value));
    }
  };

  return (
    <div>
      <Card
        sx={{
          borderRadius: "5px",
          boxShadow: "none",
          padding: "24px",
          paddingBottom: 0,
          overflow: "visible",
        }}
      >
        <div className={classes.main}>
          <ThemeProvider theme={theme}>
            <Card
              sx={{
                boxShadow: "none",
              }}
            >
              <Stack
                className={classes.filterTab}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack className={classes.filters} width="100%" direction="row" alignItems="center">
                  <Tabs
                    value={tabValue}
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={handleChange}
                  >
                    <Tab
                      style={tabValue === 0 ? style : {}}
                      label="Appointment"
                      {...a11yProps(0)}
                    />
                    <Tab style={tabValue === 1 ? style : {}} label="OIC" {...a11yProps(1)} />
                    <Tab style={tabValue === 2 ? style : {}} label="Calculator" {...a11yProps(2)} />
                  </Tabs>
                </Stack>
              </Stack>
            </Card>
          </ThemeProvider>
          {tabValue in [0, 1] && (
            <div className={classes.filters}>
              <Select
                className={classes.datePicker}
                placeholder="Select campaign"
                isMulti
                options={options}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                value={displayValue}
                onChange={handleCampaignValueChange}
                allowSelectAll
              />
            </div>
          )}
        </div>
      </Card>
      {tabValue in [0, 1] && (
        <>
          <SuiTypography variant="h6" fontWeight="medium">
            <Button
              className={classes.buttonStyle}
              onClick={toggleVisibility}
              startIcon={!isVisible ? <ChevronDown /> : <ChevronUp />}
            >
              <Typography className={classes.typographyLabelStyle} component="div">
                Kanban
              </Typography>
            </Button>
          </SuiTypography>
          <div
            style={{
              display: isVisible ? "block" : "none",
            }}
          >
            <ThemeProvider theme={theme}>
              <KanbanInfo
                isFavourite={isFavourite}
                tabValue={tabValue}
                customerUser={customerUser}
                campaignValue={campaignValue}
                setTabValue={setTabValue}
                handleValueChanged={handleValueChanged}
              />
            </ThemeProvider>
          </div>
        </>
      )}
      <SuiTypography variant="h6" fontWeight="medium">
        <Button
          className={classes.buttonStyle}
          onClick={roiVisibillity}
          startIcon={!isRoiVisible ? <ChevronDown /> : <ChevronUp />}
        >
          <Typography className={classes.typographyLabelStyle} component="div">
            ROI Data
          </Typography>
        </Button>
      </SuiTypography>
      <div
        style={{
          display: isRoiVisible ? "block" : "none",
        }}
      >
        {tabValue === 2 ? (
          <ThemeProvider theme={theme}>
            <div>
              <SimpleTabInfo customerUser={customerUser} />
            </div>
          </ThemeProvider>
        ) : (
          <DynamicCals
            key={tabValue}
            customerUser={customerUser}
            campaignValue={campaignValue}
            tabValue={tabValue}
            refreshData={refreshData}
          />
        )}
      </div>
    </div>
  );
}

export default DynamicTabInfo;
DynamicTabInfo.propTypes = {
  customerUser: PropTypes.any.isRequired,
};
