/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
// import { Bar } from "react-chartjs-2";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
// import BarReportsChartItem from "examples/Charts/BarCharts/ReportsBarChart/ReportsBarChartItem";

// ReportsBarChart configurations
// import configs from "examples/Charts/BarCharts/ReportsBarChart/configs";
import ReportChartItem from "components/authentication_v2/ReportChartItem";
// import { Box } from "@mui/material";

function ReportChart({ color, title, description, items }) {
  // const { data, options } = configs(chart.labels || [], chart.datasets || {});

  const renderItems = items.map(({ icon, label, progress }) => (
    <Grid
      item
      sx={{
        background: "white",
        borderRadius: "5px",
        marginTop: { xs: "20px", sm: "10px", md: "0", lg: "0" },
      }}
      xs={12}
      md={12}
      mb={2}
      lg={3}
      // ml={1.5}
      key={label}
    >
      <ReportChartItem
        color={color}
        icon={{ color: icon.color, component: icon.component }}
        label={label}
        progress={{ content: progress.content, percentage: progress.percentage }}
      />
    </Grid>
  ));

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={10} lg={9}>
        <SuiBox px={0.5}>
          <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {title}
          </SuiTypography>
          <SuiTypography component="div" variant="button" color="text" fontWeight="regular">
            {description}
          </SuiTypography>
          <Grid container spacing={2}>
            {renderItems}
          </Grid>
        </SuiBox>
      </Grid>
    </Grid>
  );
}

// Setting default values for the props of ReportsBarChart
ReportChart.defaultProps = {
  color: "",
  description: "",
  items: [],
};

// Typechecking props for the ReportsBarChart
ReportChart.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  // chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
};

export default ReportChart;
