import { deMill } from "@react-jvectormap/germany";
import { VectorMap } from "@react-jvectormap/core";
import { Card } from "@mui/material";
import SuiBox from "components/SuiBox";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";

const SuiVectorMapRoot = ({
  appointmentsOrOICLeads,
  fetchCampaignAppointmentsOrOics,
  openAppointmetModal,
  reviewStatus,
  campaignId,
}) => {
  const mapData = {};

  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    setMarkers([]);
    if (appointmentsOrOICLeads && appointmentsOrOICLeads.length > 0) {
      const markerVals = appointmentsOrOICLeads.map((appointment) => {
        const marker = {
          latLng: [appointment.lat, appointment.lng],
          name: `${appointment.lead_identificator} (${appointment.full_address ?? ""})`,
          label: `${appointment.lead_identificator} (${appointment.full_address ?? ""})`,
          key: appointment.id,
          className: `event-${appointment.class_name}`,
          class_name: `event-${appointment.class_name}`,
          id: appointment.id,
        };

        return marker;
      });
      if (markerVals.length > 0) {
        setMarkers(markerVals);
      }
    }
    return () => {};
  }, [appointmentsOrOICLeads]);

  const handleMarkerClick = (e, i) => {
    // console.log(markers[i].key, i);
    if (markers[i].className === "event-info") {
      Swal.fire({
        title: "Appointment Confirmation",
        text: "Do you want to confirm the appointment?",
        icon: "info",
        showCancelButton: true,
        // confirmButtonColor: "#3085d6",
        // cancelButtonColor: "#d33",
        confirmButtonText: "Yes, confirm it!",
      }).then((result) => {
        if (result.isConfirmed) {
          const context = {
            is_confirmation_form: "yes",
            review_status: "accepted",
          };
          axios
            .post(`/api/appointmentsOrOICLeads/${markers[i].key}/update-review-status`, context)
            .then((r) => {
              Swal.fire("Success!", r.message, "success");
              fetchCampaignAppointmentsOrOics(campaignId, reviewStatus);
            })
            .catch((err) => {
              Swal.fire("Error!", err.message, "error");
            });
        }
      });
    } else if (markers[i].className === "event-error") {
      Swal.fire("Warning!", "You are not allowed to access the overview page", "warning");
    } else {
      openAppointmetModal(markers[i]);
    }
  };

  return markers.length > 0 ? (
    <Card>
      <SuiBox
        p={1}
        sx={{
          backgroundColor: "none !important",
          "& .jvectormap-zoomout": {
            backgroundColor: "#FF4D00D9 !important" /* Change this to your desired color */,
            padding: "4px",
          },
          "& .jvectormap-zoomin": {
            backgroundColor: "#FF4D00D9 !important" /* Change this to your desired color */,
            padding: "4px",
          },
        }}
      >
        <VectorMap
          map={deMill}
          markers={markers}
          onMarkerClick={handleMarkerClick}
          backgroundColor=""
          style={{
            height: "30vh",
            // width: "100vh",
            borderRadius: "20px",
            backgroundColor: "none !important",
            padding: "4px",
          }}
          regionStyle={{
            initial: {
              fill: "#dadde9",
              "fill-opacity": 0.9,
              stroke: "none",
              "stroke-width": 0,
              "stroke-opacity": 0,
            },
            hover: {
              fill: "#b7b8ca",
              "fill-opacity": 1.1,

              "stroke-width": 1,
              "stroke-opacity": 1,
            },
          }}
          series={{
            regions: [
              {
                values: mapData,
                scale: ["#AAAAAA", "#444444"],
                normalizeFunction: "polynomial",
              },
            ],
          }}
        />
      </SuiBox>
    </Card>
  ) : (
    ""
  );
};

// Setting default values for the props of SuiTagInput
SuiVectorMapRoot.defaultProps = {
  appointmentsOrOICLeads: [],
  fetchCampaignAppointmentsOrOics: () => {},
  reviewStatus: ["info", "warning", "success", "error"],
  campaignId: 0,
};

// // Typechecking props for the SuiTagInput
SuiVectorMapRoot.propTypes = {
  appointmentsOrOICLeads: PropTypes.arrayOf(PropTypes.any),
  fetchCampaignAppointmentsOrOics: PropTypes.func,
  openAppointmetModal: PropTypes.func.isRequired,
  reviewStatus: PropTypes.array,
  campaignId: PropTypes.number,
};

export default SuiVectorMapRoot;
