import SuiBox from "components/SuiBox";
import React from "react";
import PropTypes from "prop-types";
import { ButtonBase } from "@mui/material";

function Status({ reviewStatus, reviewStatusVal, onStatusButton }) {
  return (
    <SuiBox mt={2} mb={2} pl={3} display="flex" alignItems="center" gap={1}>
      <ButtonBase
        sx={{
          borderRadius: "5px",
          backgroundColor: reviewStatus.includes("info") ? "#FF4D00D9" : "#FFFFFF",
          border: "none",
          boxShadow: "none",
          padding: "10px 40px",
          color: reviewStatus.includes("info") ? "#fff" : "#000000",
        }}
        variant={reviewStatus.indexOf("info") > -1 ? "gradient" : "outlined"}
        onClick={() => onStatusButton("info")}
      >
        New appointments ({reviewStatusVal.info})
      </ButtonBase>
      <ButtonBase
        sx={{
          borderRadius: "5px",
          backgroundColor: reviewStatus.includes("warning") ? "#FF4D00D9" : "#FFFFFF",
          border: "none",
          boxShadow: "none",
          padding: "10px 40px",
          color: reviewStatus.includes("warning") ? "#fff" : "#000000",
        }}
        variant={reviewStatus.indexOf("approved") > -1 ? "gradient" : "outlined"}
        onClick={() => onStatusButton("warning")}
      >
        Approved ({reviewStatusVal.warning})
      </ButtonBase>
      <ButtonBase
        sx={{
          borderRadius: "5px",
          backgroundColor: reviewStatus.includes("success") ? "#FF4D00D9" : "#FFFFFF",
          border: "none",
          boxShadow: "none",
          padding: "10px 40px",
          color: reviewStatus.includes("success") ? "#fff" : "#000000",
        }}
        variant={reviewStatus.indexOf("success") > -1 ? "gradient" : "outlined"}
        onClick={() => onStatusButton("success")}
      >
        Successful ({reviewStatusVal.success})
      </ButtonBase>
    </SuiBox>
  );
}

Status.propTypes = {
  reviewStatus: PropTypes.array.isRequired,
  onStatusButton: PropTypes.func.isRequired,
  reviewStatusVal: PropTypes.array.isRequired,
};

export default Status;
