import { Box, CircularProgress, Grid, Stack, ThemeProvider, Typography, Icon } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { createTheme } from "@mui/material/styles";
import axios from "axios";
import { useState, useContext, useRef, useEffect } from "react";
import Swal from "sweetalert2";
import NotesContext from "AppContext";
import ReactHtmlParser from "react-html-parser";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import AuthButton from "components/Buttons/AuthButton";
import AppointmentModal from "layouts/dashboards/appointments/components/AppointmentModal";
import CloseIcon from "../../../assets/images/enrichment/close.svg";
import PaperPlane from "../../../assets/images/authentication_v2/PaperPlane.svg";
import avatarChat from "../../../assets/images/authentication_v2/avatarChat.svg";

function NewMessage({ closeChat, appointment, customerUser, onlineUsers }) {
  const [note, setNote] = useState("");
  const [notes, setNotes] = useState([]);
  const [sendLoading, setSendLoading] = useState(false);
  const inputRef = useRef(null);
  const [openAppointmentModal, setOpenAppointmentModal] = useState(false);
  const { totalNewNotes, setTotalNewNotes } = useContext(NotesContext);
  const history = useHistory();
  const [selectedApointmentId, setSelectedApointmentId] = useState(null);
  const isEnabled = () => {
    if (note) return false;
    return true;
  };

  const handleCloseAppointmentModal = () => {
    setSelectedApointmentId(null);
    setOpenAppointmentModal(false);
  };

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const fetchNotes = () => {
    axios
      .get(
        `/api/customers/${customerUser.customer.id}/campaign-leads/${appointment.campaign_lead.id}/notes/mark-notes-as-read`
      )
      .then((res) => {
        setTotalNewNotes(res.data && res.data.message && res.data.message);
      })
      .catch(() => {});

    axios
      .get(
        `/api/customers/${customerUser.customer.id}/campaign-leads/${appointment.campaign_lead.id}/notes/order-by/asc`
      )
      .then((res) => {
        const notesRes = res.data.data.map((r) => ({
          image: avatarChat,
          id: r.id,
          name: r.author,
          authorId: r.author_id,
          message: r.message,
          createdAt: r.created_at,
          canBeDeleted: r.can_be_deleted,
        }));
        setNotes(notesRes);
      })
      .catch(() => {
        setNotes([]);
      });
  };

  const onSubmitNote = () => {
    setSendLoading(true);
    const payload = {
      note,
    };
    axios
      .post(
        `/api/customers/${customerUser.customer.id}/campaign-leads/${appointment.campaign_lead.id}/create-note`,
        payload
      )
      .then(({ data }) => {
        setSendLoading(false);
        const r = data.data;
        setNotes((current) => [
          ...current,
          {
            image: avatarChat,
            id: r.id,
            name: "me",
            authorId: r.author_id,
            message: r.message,
            createdAt: r.created_at,
            canBeDeleted: true,
          },
        ]);
        setNote("");

        if (inputRef.current) {
          inputRef.current.value = "";
        }
      })
      .catch(() => {
        setSendLoading(false);
        fetchNotes();
        setNotes([]);
        setNote("");

        if (inputRef.current) {
          inputRef.current.value = "";
        }
      });
  };

  useEffect(() => {
    scrollToBottom();
    return () => {};
  }, [notes]);

  useEffect(() => {
    fetchNotes();
    return () => {};
  }, [appointment, customerUser, totalNewNotes, openAppointmentModal]);

  const eraseNote = (id) => {
    axios
      .delete(
        `/api/customers/${customerUser.customer.id}/campaign-leads/${appointment.campaign_lead.id}/notes/${id}/delete`
      )
      .then(() => {
        fetchNotes();
      })
      .catch(() => {
        setNotes([]);
      });
  };

  const useStyles = makeStyles(() => ({
    value: {
      fontFamily: "Poppins",
      textAlign: "center",
      fontSize: "20px",
      fontWeight: "400",
      color: "#4A4A4A",
      marginTop: "10px",
      marginBottom: "10px",
    },
    description: {
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: "300",
      color: "#4A4A4A",
      marginTop: "10px",
      width: "100%",
      paddingRight: "5px",
      marginBottom: "10px",
      textWrap: "wrap",
    },
    button: {
      border: "1px solid #5c5c5c",
      borderRadius: "20px",
      backgroundColor: "white",
      color: "#5c5c5c",
      padding: "8px 15px",
      fontSize: "14px",
      cursor: "pointer",
      width: "200px",
      marginLeft: "80px",
    },
    close: {
      cursor: "pointer",
      marginRight: "10px",
    },
    stackMessages: {
      width: "100%",
      minWidth: "230px",
      padding: "10px 7px 10px 15px",
      borderRadius: "5px",
      display: "flex",
      flexDirection: "row",
      wordBreak: "break-word",
      overflowWrap: "normal",
    },
    messages: {
      border: "none",
      fontSize: "12px",
      fontWeight: "400",
      fontFamily: "Poppins",
      color: "#4A4A4A",
    },
    body: {
      width: "100%",
      height: "90%",
      marginTop: "5%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      overflowY: "scroll",
      padding: "0 4%",
      paddingBottom: "3%",
      "& .css-h4p8d0": {
        marginTop: "7%",
        marginBottom: "3%",
      },
    },
    input: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      width: "96%",
      backgroundColor: "white",
      border: "none",
    },
    sendButton: {
      background: "transparent",
      border: "0",
      cursor: isEnabled() ? "default" : "pointer",
      marginLeft: "10px",
      width: "4%",
    },
  }));
  const classes = useStyles();

  const theme = createTheme({
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          circle: {
            color: "lightgrey",
          },
        },
      },
    },
  });

  const handleRefreshList = () => {
    fetchNotes();
  };

  const handleEventClick = (pAppointment) => {
    if (pAppointment.review_status.includes("to_do")) {
      Swal.fire({
        title: "Appointment Confirmation",
        text: "Do you want to confirm the appointment?",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, confirm it!",
      }).then((result) => {
        if (result.isConfirmed) {
          const context = {
            is_confirmation_form: "yes",
            review_status: "accepted",
          };
          axios
            .post(
              `/api/customers/${customerUser.customer.id}/campaigns/${pAppointment.campaign_id}/appointments/${pAppointment.id}/update-review-status`,
              context
            )
            .then(() => {
              fetchNotes();
              history.push({
                pathname: "/enrichment",
                state: {
                  appointmentIdParam: pAppointment.id,
                  customerUser: { customerUser },
                  campaignId: appointment.campaign_id,
                  appointmentId: selectedApointmentId,
                },
              });
            })
            .catch((err) => {
              Swal.fire("Error!", err.message, "error");
            });
        }
      });
    } else if (pAppointment.class_name.includes("error")) {
      Swal.fire("Warning!", "You are not allowed to access the overview page", "warning");
    } else {
      history.push({
        pathname: "/enrichment",
        state: {
          appointmentIdParam: pAppointment.id,
          customerUser: { customerUser },
          campaignId: appointment.campaign_id,
          appointmentId: selectedApointmentId,
        },
      });
    }
  };

  const handleInputChange = (event) => {
    setNote(event.target.value);
  };
  return (
    <>
      <ThemeProvider theme={theme}>
        {openAppointmentModal && selectedApointmentId ? (
          <AppointmentModal
            customerUser={customerUser}
            campaignId={appointment.campaign_id}
            appointmentId={selectedApointmentId}
            open={openAppointmentModal}
            handleClose={handleCloseAppointmentModal}
            refreshList={handleRefreshList}
            redirectionEnabled={false}
          />
        ) : (
          ""
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid #5c5c5c",
          }}
        >
          <img style={{ padding: "10px" }} src={avatarChat} alt={avatarChat} />
          <Typography className={classes.description}>
            {appointment.lead_identificator.replace("Firmenname: ", "")}
          </Typography>
          <div style={{ marginTop: "5px" }}>
            <Typography onClick={() => closeChat(appointment.id)}>
              <img width={25} src={CloseIcon} className={classes.close} alt="Close" />
            </Typography>
          </div>
        </div>
        <Box className={classes.body}>
          {!appointment.review_status.includes("to_do") ? (
            notes &&
            notes.length > 0 &&
            notes.map(({ image, id, name, message, canBeDeleted, authorId }) => (
              <Box
                key={id}
                display="flex"
                alignItems={name === "me" ? "flex-end" : "flex-start"}
                flexDirection="column"
                mt={2}
              >
                <Box
                  className={classes.boxMessages}
                  sx={
                    name === "me"
                      ? {
                          display: "flex",
                          flexDirection: "row-reverse",
                          gap: "15px",
                        }
                      : {
                          display: "flex",
                          flexDirection: "row",
                          gap: "15px",
                        }
                  }
                >
                  <div style={{ position: "relative" }}>
                    {name !== "me" && (
                      <div
                        className={
                          onlineUsers.includes(authorId) ? "online-indicator" : "offline-indicator"
                        }
                      >
                        <span className="blink" />
                      </div>
                    )}
                    <img src={image} alt={name} />
                  </div>

                  <Stack
                    sx={
                      name === "me"
                        ? { backgroundColor: "#FF4D00D9" }
                        : { backgroundColor: "#F7F7F7" }
                    }
                    className={classes.stackMessages}
                  >
                    <Typography
                      sx={
                        name === "me"
                          ? { color: "white !important" }
                          : { color: "#4A4A4A !important" }
                      }
                      className={classes.messages}
                    >
                      {ReactHtmlParser(message)}
                    </Typography>
                  </Stack>
                  <Stack flexDirection="row" alignItems="center">
                    {canBeDeleted ? (
                      <Typography onClick={() => eraseNote(id)} sx={{ cursor: "pointer" }}>
                        <Icon sx={{ color: "#5F5F5FCC", fontSize: "15px" }}>delete</Icon>
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Stack>
                </Box>
              </Box>
            ))
          ) : (
            <Grid container>
              <Grid item xs={12} textAlign="center">
                <AuthButton onClick={() => handleEventClick(appointment)}>
                  Approve&nbsp;<Icon sx={{ fontWeight: "bold" }}>check</Icon>
                </AuthButton>
              </Grid>
            </Grid>
          )}
          <div ref={messagesEndRef} />
        </Box>
        {appointment &&
        !appointment.review_status.includes("to_do") &&
        !appointment.class_name.includes("error") ? (
          <Stack direction="row" alignItems="center">
            <form className={classes.input}>
              <input
                style={{
                  border: "0",
                  backgroundColor: "#F7F7F7",
                  borderRadius: "5px",
                  paddingTop: "2%",
                  paddingBottom: "2%",
                  paddingLeft: "2%",
                  width: "100%",
                  height: "40px",
                  outline: "none",
                }}
                type="text"
                placeholder="Write here"
                value={note}
                onChange={handleInputChange}
              />
              {appointment &&
                !appointment.review_status.includes("to_do") &&
                !appointment.class_name.includes("error") &&
                (sendLoading ? (
                  <ThemeProvider theme={theme}>
                    <Stack width="4%" ml={1}>
                      <CircularProgress size="100%" />
                    </Stack>
                  </ThemeProvider>
                ) : (
                  <button
                    className={classes.sendButton}
                    disabled={isEnabled()}
                    type="submit"
                    onClick={onSubmitNote}
                  >
                    <img src={PaperPlane} alt="Send" />
                  </button>
                ))}
            </form>
          </Stack>
        ) : (
          ""
        )}
      </ThemeProvider>
    </>
  );
}
NewMessage.propTypes = {
  closeChat: PropTypes.func.isRequired,
  appointment: PropTypes.any.isRequired,
  customerUser: PropTypes.any.isRequired,
  onlineUsers: PropTypes.array.isRequired,
};

export default NewMessage;
